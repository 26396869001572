import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  createElement,
} from "react";
import Image from "@/components/Image/image";
import styles from "./coverflow-carousel.module.scss";
import { gsap } from "gsap";
import classnames from "classnames";
import IconChevronLeft from "@ef-global/web-ui-react/lib/icons/IconChevronLeft";
import IconChevronRight from "@ef-global/web-ui-react/lib/icons/IconChevronRight";
import Components from "@/components/components";
import SbEditable from "storyblok-react";
import layoutStyles from "../Layout/layout.module.scss";
import {
  AssetStoryblok,
  CoverflowCarouselSlideStoryblok,
  CoverflowCarouselStoryblok,
} from "@/types/component-types-sb";

interface Props {
  blok: CoverflowCarouselStoryblok;
}

const CoverflowCarousel = (props: Props) => {
  const {
    blok: { items = [] },
  } = props;
  const duration = 10;
  const numBoxes = items.length;
  const [tl] = useState(
    gsap.timeline({ ease: "none", repeat: -1, paused: true, loop: true })
  );
  const [contentTl] = useState(
    gsap.timeline({ ease: "none", repeat: -1, paused: true, loop: true })
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);

  // refs
  const slideRefs = useRef<HTMLDivElement[]>([]);
  const imageRefs = useRef<HTMLDivElement[]>([]);
  const contentRefs = useRef<HTMLDivElement[]>([]);
  const carouselRef = useRef<HTMLDivElement>(null);
  const images = useRef<HTMLDivElement>(null);

  const addToRefs = (
    el: HTMLDivElement | null,
    refArray: React.MutableRefObject<HTMLDivElement[]>
  ) => {
    if (refArray.current && el !== null && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  useEffect(() => {
    tl.clear();
    contentTl.clear();
    const rotation = 360 / numBoxes;

    slideRefs.current.forEach((el, i) => {
      gsap.set(el, {
        transform: `rotateY(${i * rotation}deg) translateZ(50px) rotateY(-${
          i * rotation
        }deg)`,
      });
    });

    contentRefs.current.forEach((el, i) => {
      contentTl
        .fromTo(
          el,
          { autoAlpha: 0, y: 20 },
          { autoAlpha: 1, y: 0, duration: duration / numBoxes / 2 },
          i * (duration / numBoxes)
        )
        .to(el, { autoAlpha: 0, y: -20, duration: duration / numBoxes / 2 });
    });

    contentTl.progress(0.1);

    tl.to(imageRefs.current, { rotateY: 360, duration, ease: "none" }, 0).to(
      images.current,
      { rotateY: -360, duration, ease: "none" },
      0
    );

    return () => {
      tl.kill();
      contentTl.kill();
    };
  }, [numBoxes]);

  const updateSlides = (direction: string) => {
    if (isPlaying) return;
    const slideProgress = 1 / numBoxes;
    if (direction === "next") {
      setCurrIndex(currIndex + 1);
      gsap.to([tl, contentTl], {
        duration: 1,
        progress: `+=${slideProgress}`,
        ease: "power1.easeOut",
        onStart: () => setIsPlaying(true),
        onComplete: () => setIsPlaying(false),
      });
    } else if (direction === "prev") {
      setCurrIndex(currIndex - 1);
      gsap.to([tl, contentTl], {
        duration: 1,
        progress: `-=${slideProgress}`,
        ease: "power1.easeOut",
        onStart: () => setIsPlaying(true),
        onComplete: () => setIsPlaying(false),
      });
    }
  };

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <div className={styles.wrapper} ref={carouselRef}>
        <div className={styles.coverflowImageGroupWrap}>
          <div className={styles.coverflowImageGroup}>
            <div className={styles.coverflowImages} ref={images}>
              {items?.length
                ? items.map((item, i) =>
                    item.image ? (
                      <div
                        key={item._uid}
                        ref={(el) => addToRefs(el, slideRefs)}
                      >
                        <CoverflowImageSlide
                          ref={(el) => addToRefs(el, imageRefs)}
                          image={item.image}
                          className={classnames(`slide-image-${i}`)}
                        />
                      </div>
                    ) : null
                  )
                : null}
            </div>
          </div>
        </div>
        <div className={styles.controls}>
          <button
            type="button"
            className={classnames(
              styles.controlButtonRound,
              currIndex === 0 ? styles.inactive : ""
            )}
            onClick={() => updateSlides("prev")}
          >
            <IconChevronLeft />
          </button>
          <button
            type="button"
            className={classnames(styles.controlButtonRound)}
            onClick={() => updateSlides("next")}
          >
            <IconChevronRight />
          </button>
        </div>
        <div className={styles.coverflowContent}>
          {items.length
            ? items.map((item, i) => (
                <CoverflowContentSlide
                  key={`${item._uid}-b`}
                  ref={(el) => addToRefs(el, contentRefs)}
                  content={item.content}
                  className={classnames(`slide-content-${i}`)}
                />
              ))
            : null}
        </div>
      </div>
    </SbEditable>
  );
};

interface CoverflowImageSlideProps {
  image: AssetStoryblok;
  className?: string;
  numItems?: number;
}

const CoverflowImageSlide = forwardRef<
  HTMLDivElement,
  CoverflowImageSlideProps
>((props, ref) => {
  const { image, numItems, className, ...rest } = props;
  const classes = classnames(styles.imageSlide, className);

  return image.filename ? (
    <div className={classes} ref={ref} {...rest}>
      <Image
        className={styles.image}
        filename={image.filename}
        alt={image.alt}
        focus={image.focus}
        aspectMobile={"1/1"}
        aspectDesktop={"1/1"}
        priority
      />
    </div>
  ) : null;
});

interface CoverflowContentSlideProps {
  content: CoverflowCarouselSlideStoryblok["content"];
  className?: string;
}

const CoverflowContentSlide = forwardRef<
  HTMLDivElement,
  CoverflowContentSlideProps
>((props, ref) => {
  const { content = [], className } = props;
  const classes = classnames(styles.contentSlide, className);

  return content.length ? (
    <div className={classes} ref={ref}>
      {content.map((blok) => {
        return createElement(Components(blok.component), {
          blok,
          key: blok._uid,
          className: classnames(
            layoutStyles.layoutComponent,
            layoutStyles[blok.space_above],
            layoutStyles[blok.space_above_l]
          ),
        });
      })}
    </div>
  ) : null;
});

export default CoverflowCarousel;
